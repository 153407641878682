import React from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import SmallHeroSection from "../components/SmallHeroSection";
import DecorativeSection from "../components/DecorativeSection";

const PolicyPage = () => {
    return (

        <Layout page="regulamin">
            <Seo title="Regulamin" />
            <SmallHeroSection />
            <DecorativeSection />

            <section>
                <div className="container">
                    <p align="center">
                        <b>REGULAMIN </b><br />
                        <b>BARWY ZDROWIA CATERING DIETETYCZNY ILONA KOT</b>
                    </p>
                    <p align="center">
                        <b>§1</b>
                    </p>
                    <p align="justify">
                        1. Regulamin określa zasady oraz usługi oferowane przez BARWY ZDROWIA
                        CATERING DIETETYCZNY ILONA KOT z siedzibą w Tarnowie (33-100) przy ul.
                        Dworcowej 3, NIP: 8733199697, REGON: 369268484.
                    </p>
                    <p align="justify">
                        2. Niniejszy regulamin obowiązuje od dnia 19 stycznia 2018 roku.
                    </p>
                    <p align="center">
                        <b>§2</b>
                    </p>
                    <p align="justify">
                        1. Regulamin – niniejszy dokument
                    </p>
                    <p align="justify">
                        2. Catering - BARWY ZDROWIA CATERING DIETETYCZNY ILONA KOT z siedzibą w
                        Tarnowie (33-100) przy ul. Dworcowej 3, NIP: 8733199697, REGON: 369268484.
                    </p>
                    <p align="justify">
                        3. Klient – osoba, z którą łączy umowa usług świadczonych przez Catering.
                    </p>
                    <p align="justify">
                        4. Klient stacjonarny – osoba korzystająca z Cateringu bez uprzedniego
                        podpisania umowy na świadczenie usług
                    </p>
                    <p align="justify">
                        5. Zestaw – paczka z odpowiednio zbilansowanymi posiłkami wg. zamówienia
                    </p>
                    <p align="center">
                        <b>§3</b>
                    </p>
                    <p align="justify">
                        1. Catering świadczy usługi cateringowe polegające na przygotowywaniu
                        odpowiednio zbilansowanych posiłków, a także pojedynczych posiłków
                        dostępnych do odbioru w siedzibie firmy.
                    </p>
                    <p align="justify">
                        2. Klienci korzystający z Cateringu zobowiązani są do wyboru jednego z
                        dziewięciu oferowanych zestawów, które dzielą się na:
                    </p>
                    <div className="container">
                        <p>
                            a{'\u0029'} Optimal – 1200 kcal
                        </p>
                        <p>
                            b{'\u0029'} Optimal – 1300 kcal
                        </p>
                        <p>
                            c{'\u0029'} Optimal – 1400 kcal
                        </p>
                        <p>
                            d{'\u0029'} Optimal – 1500 kcal
                        </p>
                        <p>
                            e{'\u0029'} Optimal – 1800 kcal
                        </p>
                        <p>
                            f{'\u0029'} Optimal – 2000 kcal
                        </p>
                        <p>
                            g{'\u0029'} Sport – 2500 kcal
                        </p>
                        <p>
                            h{'\u0029'} Sport – 3000 kcal
                        </p>
                        <p>
                            i{'\u0029'} Sport – 3500 kcal
                        </p>
                    </div>
                    <p align="justify">
                        3. Klient przed skorzystaniem z usług cateringowych zobowiązany jest do
                        udzielenia informacji o:
                    </p>
                    <div className="container">
                        <p align="justify">
                            a{'\u0029'} Dane personalne {'\u0028'}mię i nazwisko oraz adres do dostawy, numer telefonu{'\u0029'}
                        </p>
                        <p align="justify">
                            b{'\u0029'} Wykaz ewentualnych produktów, jakie nie mogą znaleźć się w posiłkach
                        </p>
                        <p align="justify">
                            c{'\u0029'} Informacje odnoszące się do zamówienia, a więc ilości dni na jakie mają
                            otrzymywać zamówienie, a także ilość posiłków, gdzie maksymalna ilość
                            wynosi sześć
                        </p>
                        <p align="justify">
                            d{'\u0029'} Szczegółowe informacje o zamówieniach znajdują się w umowach zawieranych
                            przez Klientów
                        </p>
                        <p align="justify">
                            e{'\u0029'} Administratorem danych osobowych gromadzonych i przechowywanych w celu
                            prawidłowej realizacji umowy zawartej z Klientem jest właściciel Cateringu
                            – Ilona Kot.
                        </p>
                        <p align="justify">
                            f{'\u0029'} Podpisanie umowy o świadczenie usług cateringowych jest związany z
                            jednoczesną akceptacją na przetwarzanie danych osobowych niezbędnych do
                            realizacji umowy.
                        </p>
                        <p align="justify">
                            g{'\u0029'} Klient w każdym czasie i dowolnej formie ma prawo wycofać zgodę na
                            przetwarzanie danych osobowych.
                        </p>
                        <p align="justify">
                            h{'\u0029'} Klient podając swój numer telefonu jest zainteresowany otrzymywaniem informacji o złożonym zamówieniu, dostawach, ofertach i informacjach o rabatach.
                        </p>
                    </div>
                    <p>
                        4. Klient może złożyć zamówienie poprzez:
                    </p>
                    <div className="container">
                        <p>
                            a{'\u0029'} Kontakt telefoniczny
                        </p>
                        <p>
                            b{'\u0029'} Kontakt mailowy
                        </p>
                        <p>
                            c{'\u0029'} Kontakt osobisty
                        </p>
                        <p>
                            d{'\u0029'} Stronę internetową <a href="https://www.barwy-zdrowia.pl/zamowienia">www.barwy-zdrowia.pl</a>
                        </p>
                    </div>
                    <p align="justify">
                        5. Wycena poszczególnych zestawów następuje w dniu podpisania umowy i jest
                        w nich zawarta po uprzedniej akceptacji przez Klienta.
                    </p>
                    <p align="justify">
                        6. Zarówno Klient, jak i Catering mają prawo do zmian warunków umowy po
                        uprzednim ustaleniu oraz akceptacji z drugą stroną. Zmiany te muszą zostać
                        zawarte pisemnie pod rygorem nieważności.
                    </p>
                    <p align="justify">
                        7. Umowa łącząca Klienta z Cateringiem jest możliwa do rozwiązania przez
                        obie strony w każdym czasie, przy czym warunki zakończenia współpracy
                        określane są w umowach.
                    </p>
                    <p align="justify">
                        8. Klienci mają prawo do skorzystania z dowozu bezpośrednio pod wskazany
                        przez nich adres bez dodatkowego uiszczania opłaty, o tyle o ile mieszkają
                        w obrębie 20 km od miejscowości Tarnowa, a także Klienci z Zakliczyna,
                        Tuchowa i Brzeska.
                    </p>
                    <p align="justify">
                        9. Dostawy możliwe są w każdym dniu tygodnia.
                    </p>
                    <p align="justify">
                        10. Po złożeniu zamówienia Catering zobowiązany jest do dostarczenie
                        zamówionych posiłków w dniu wskazanym przez Klienta.
                    </p>
                    <p align="justify">
                        11. Zamówień zestawów można dokonać w każdym czasie, jednakże najpóźniej
                        dzień poprzedzający dzień otrzymania posiłków.
                    </p>
                    <p align="justify">
                        12. Możliwe jest dostarczenie posiłków tego samego dnia o ile zamówienie
                        zostanie złożone tego dnia najpóźniej do godziny 10:00
                    </p>
                    <p align="center">
                        <b>§4</b>
                    </p>
                    <p align="justify">
                        1. Zamówienia wyceniane są dla każdego Klienta indywidualnie, przy czym pod
                        uwagę bierze się ilość posiłków na jeden dzień i wartość odżywczą.
                    </p>
                    <p align="justify">
                        2. Klient dokonuje zapłaty przed dniem otrzymania zamówienia
                    </p>
                    <p align="justify">
                        3. Klient ma prawo zapłacić gotówką podczas pierwszej dostawy lub w
                        siedzibie firmy.
                    </p>
                    <p align="justify">
                        4. Klient ma prawo zapłacić wykonując przelew na rachunek bankowy wskazany
                        przez Catering, przy czym za dzień zapłaty uznaje się dzień wykonania
                        przelewu.
                    </p>
                    <p align="justify">
                        5. Jeżeli klient dokona płatności przelewem i poświadczy wykonanie
                        transakcji, Catering uznaje dzień wskazania dowodu zapłaty, za dzień
                        dokonania płatności.
                    </p>
                    <p align="center">
                        <b>§5</b>
                    </p>
                    <p align="justify">
                        1. Klient stacjonarny może skorzystać z usługi cateringu w każdym czasie,
                        która polega na wydaniu posiłku, jaki w danym dniu dostępny jest w
                        siedzibie firmy.
                    </p>
                    <p align="justify">
                        2. Posiłki dostępne dla Klientów stacjonarnych są przygotowywane przed
                        otwarciem Cateringu w danych dniu.
                    </p>
                    <p align="justify">
                        3. Klient stacjonarny nie ma możliwości skomponowania własnego danie.
                    </p>
                    <p align="justify">
                        4. Klient stacjonarny może wybrać jeden z posiłków, jakie znajdują się w
                        witrynie chłodzącej.
                    </p>
                    <p align="justify">
                        5. Każdy z posiłków znajdujących się w witrynie chłodzącej posiada inną
                        wartość odżywczą, a Klient stacjonarny może sam wybrać odpowiednią dla
                        siebie, dostępną w firmie.
                    </p>
                    <p align="justify">
                        6. Zakup posiłków dostępnych w siedzibie firmy nie wymaga uprzedniego
                        podpisania umowy.
                    </p>
                    <p align="justify">
                        7. Dla Klientów stacjonarnych Catering nie przewiduje dostawy.
                    </p>
                    <p align="center">
                        <b>§6</b>
                    </p>
                    <p align="justify">
                        1. Catering przewiduje tworzenie promocji, także rabatów.
                    </p>
                    <p align="justify">
                        2. Promocje określane będą odrębnymi regulaminami.
                    </p>
                    <p align="justify">
                        3. Rabaty tworzone są indywidualnie dla każdego Klienta, które uwzględniane
                        zostają w umowach.
                    </p>
                    <p align="center">
                        <b>§7</b>
                    </p>
                    <p align="justify">
                        1. Kontakt z Cateringiem możliwy jest pod nr telefonu: <b>721 399 415</b>, email: <a href="mailto:kontakt@barwy-zdrowia.pl">kontakt@barwy-zdrowia.pl</a>, a także
                        w siedzibie firmy w dniach od poniedziałku do piątku w godzinach od <b>7:00</b> do
                        <b>15:00</b></p>
                    <p></p>
                    <p align="justify">
                        2. Zasady określone w regulaminie obowiązują od dnia 19 stycznia 2018 roku
                    </p>
                    <p align="justify">
                        3. Zmiany regulaminu dokonuje się pisemnie pod rygorem nieważności
                    </p>
                    <p align="justify">
                        4. Sprawy nieuregulowane niniejszym regulaminem znajdują zastosowanie
                        powszechnie obowiązujące w przepisach prawa polskiego, a w szczególności:
                        Kodeksu cywilnego; ustawy o świadczeniu usług drogą elektroniczną z dnia 18
                        lipca 2002 r. {'\u0028'}Dz.U. 2002 nr 144, poz. 1204 ze zm.{'\u0029'}; Ustawy o Prawach
                        Konsumenta oraz inne właściwe przepisy powszechnie obowiązującego prawa.
                    </p>
                </div>

            </section>
        </Layout>
    )
}

export default PolicyPage
